@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Maleo';
    src: url('../fonts/Maleo-Light.otf') format('opentype');
    font-weight: 300;
    letter-spacing: .1em;
  }
  @font-face {
    font-family: 'Maleo';
    src: url('../fonts/Maleo-Medium.otf') format('opentype');
    font-weight: 500; 
    letter-spacing: .1em;
  }
  @font-face {
    font-family: 'Maleo';
    src: url('../fonts/Maleo-Regular.otf') format('opentype');
    font-weight: 400; 
    letter-spacing: .1em;
  }
  @font-face {
    font-family: 'Acumin';
    src: url('../fonts/AcuminVariableConcept.otf') format('opentype');
    font-weight: 400; 
  }
  
  /* Apply letter-spacing to all elements using the Maleo font */
  * {
    font-family: 'Maleo', sans-serif;
    letter-spacing: 0.1em; /* Set default letter spacing for Maleo */
  }

  :root {
    --text-base-size: 1rem; /* Default size */
    --text-lg-size: 1.125rem; /* Large size */
    --text-sm-size: 0.875rem; /* Small size */
  }
  