/* ToastStyles.css */
/* Define colors based on your Tailwind theme */
:root {
    --toast-bg: #EDD1B0;  /* basin-light-brown */
    --toast-text: #3C5572; /* sea-blue */
    --error-bg: #F79330;  /* sunset-dark-orange */
    --success-bg: #F3EB43; /* sun-yellow */
    --success-text: #3C5572; /* sea-blue */
    --error-text: #F5F5F5;   /* a light color for contrast */
}

/* Customize the toast container */
.Toastify__toast-container {
    font-family: 'Maleo', sans-serif; /* Using the custom 'Maleo' font */
}

/* Customize individual toasts */
.Toastify__toast {
    background-color: var(--toast-bg);
    color: var(--toast-text);
}

.Toastify__toast--error {
    background-color: var(--error-bg);
    color: var(--error-text);
}

.Toastify__toast--success {
    background-color: var(--success-bg);
    color: var(--success-text);
}

/* Additional customizations can include borders, shadows, etc. */
